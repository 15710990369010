// Base
@import "node_modules/uikit/src/scss/variables.scss";

// Common
@import "node_modules/uikit/src/scss/components/overlay.scss"; // After: Icon

// Utilities
@import "node_modules/uikit/src/scss/components/animation.scss";
@import "node_modules/uikit/src/scss/components/width.scss";
@import "node_modules/uikit/src/scss/components/height.scss";
@import "node_modules/uikit/src/scss/components/text.scss";
@import "node_modules/uikit/src/scss/components/column.scss";
@import "node_modules/uikit/src/scss/components/cover.scss";
@import "node_modules/uikit/src/scss/components/background.scss";
@import "node_modules/uikit/src/scss/components/align.scss";
@import "node_modules/uikit/src/scss/components/utility.scss";
@import "node_modules/uikit/src/scss/components/flex.scss"; // After: Utility
@import "node_modules/uikit/src/scss/components/margin.scss";
@import "node_modules/uikit/src/scss/components/padding.scss";
@import "node_modules/uikit/src/scss/components/position.scss";
@import "node_modules/uikit/src/scss/components/visibility.scss";

@import "./layout.scss";
@import "./form.scss";
@import "./card.scss";
@import "./table.scss";
@import "./steps.scss";
@import "./color-picker.scss";
@import "./teampics-places.scss";
@import "./list.scss";
@import "./images.scss";

.ant-upload-picture-card-wrapper {
  width: unset;
}

.radio-green-highlight {
  & .ant-radio-inner {
    border-color: #30ce72;
    height: 20px;
    width: 20px;
    &::after {
      background-color: #30ce72;
      height: 12px;
      width: 12px;
    }
  }
}

.radio-error-highlight {
  & .ant-radio-inner {
    border-color: #fc0202;
    height: 20px;
    width: 20px;
    &::after {
      background-color: #fc0202;
      height: 12px;
      width: 12px;
    }
  }
}

.ant-btn-icon-only > i {
  vertical-align: -0.125em;
}

.checkbox-green-highlight {
  & span {
    & span.ant-checkbox-inner {
      background-color: #30ce72;
      border-color: #30ce72;
    }
  }
}

.checkbox-error-highlight {
  & span {
    & span.ant-checkbox-inner {
      border-color: #fc0202;
    }
  }
}
