.autocomplete {
	/*the container must be positioned relative:*/
	position: relative;
	display: inline-block;
}
.autocomplete-items {
	/*position the autocomplete items to be the same width as the container:*/
	left: 0;
	//right: 0;
	z-index: 1 !important;
	position: absolute;
}

.autocomplete-item {
	position: relative;
	display: block;
	padding: 5px 12px;
	overflow: hidden;
	color: rgba(0, 0, 0, 0.65);
	font-weight: normal;
	line-height: 22px;
	white-space: nowrap;
	text-overflow: ellipsis;
	cursor: pointer;
	-webkit-transition: background 0.3s ease;
	transition: background 0.3s ease;
	text-align: left;
}

.autocomplete-active {
	/*when navigating through the items using the arrow keys:*/
	background-color: #f0fffe !important;
	cursor: pointer;
	color: rgba(0, 0, 0, 0.65);
}
