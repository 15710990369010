$break-sm: 750px;
$break-md: 970px;
$break-lg: 1170px;

th.column-image,
td.column-image {
  max-width: 100px;
  @media screen and (max-width: $break-sm) {
    max-width: 150px;
  }
}

.col-image {
  height: 100px;
  width: 100px;
  object-fit: contain; /* Do not scale the image */
  object-position: center; /* Center the image within the element */
  display: block;
  margin: 0 auto;
}

.table-row-clickable {
  cursor: pointer;
}

.table-operations {
  margin-bottom: 16px;
}

.table-operations > button {
  margin-right: 8px;
}

.table-header {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
