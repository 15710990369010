.list {
  padding: 0;
  list-style: none;
}

.list-item {
  background-color: white;
}

.list-item:hover {
  background-color: #78f8ff;
  cursor: pointer;
}

.timeslot-item:hover {
  background-color: #78f8ff;
}

.small-list {
  min-height: 350px;
}

.list-item-taken {
  background-color: #f08080 !important;
}

.demo-infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
  height: 600px;
}
.demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}
