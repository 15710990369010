$break-sm: 750px;
$break-md: 970px;
$break-lg: 1170px;

.rounded-card {
	border-radius: 10px;
}

.login-card {
	min-width: 500px;
	@media screen and (max-width: $break-sm) {
		max-width: none;
		min-width: 300px;
	}
	@media screen and (max-height: 700px) {
		margin-top: 40px;
		margin-bottom: 20px;
	}
}
