$break-sm: 750px;
$break-md: 970px;
$break-lg: 1170px;

.settings-form {
  max-width: 800px;
  @media screen and (max-width: $break-sm) {
    max-width: none;
    min-width: 100%;
  }
}

.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
