$break-sm: 750px;
$break-md: 970px;
$break-lg: 1170px;

.empty-view {
	min-height: 300px;
	@media screen and (max-width: $break-sm) {
		min-height: 150px;
		max-height: 100px;
	}
	@media screen and (max-height: $break-sm) {
		min-height: none;
		max-height: 100px;
	}
}
