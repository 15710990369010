.color {
	width: 100%;
	height: 100%;
	border-radius: 4px;
	transition: all 0.3s;
}
.swatch {
	// padding: 5px;
	// background: #fff;
	// border-radius: 4px;
	// display: inline-block;

	width: 100%;
	height: 32px;
	padding: 4px 4px;
	border: 1px solid #d9d9d9;
	background-color: white;
	border-radius: 4px;
	transition: all 0.3s;
	position: relative;
	display: inline-block;
	box-sizing: border-box;

	cursor: pointer;
}
.popover {
	position: absolute;
	z-index: 2;
}
.cover {
	position: fixed;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
}
.overlay {
	min-width: 210px !important;
}
