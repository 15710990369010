$break-sm: 750px;
$break-md: 970px;
$break-lg: 1170px;

.steps-content {
	margin-top: 16px;
	border: 1px dashed #45c5d5;
	border-radius: 6px;
	background-color: white;
	min-height: 200px;
	text-align: center;
	padding-top: 12px;
	overflow: auto;
	max-height: 70vh;
	min-width: 50vw;
	@media screen and (max-width: $break-sm) {
		padding: 10px;
	}
	@media screen and (min-height: 600px) and (max-height: 700px) and (max-width: 480px) {
		max-height: 45vh;
	}
	@media screen and (min-height: 600px) and (max-height: 700px) and (min-width: 480px) {
		max-height: 65vh;
	}
	@media screen and (min-height: 700px) and (max-height: 800px) {
		max-height: 55vh;
	}
	@media screen and (min-height: 800px) and (max-height: 900px) {
		max-height: 60vh;
	}
	@media screen and (min-height: 900px) {
		max-height: 70vh;
	}
}

.steps-content-events {
	margin-top: 16px;
	border: 1px dashed #45c5d5;
	border-radius: 6px;
	background-color: white;
	min-height: 200px;
	text-align: center;
	padding-top: 12px;
	overflow: auto;
	max-height: 70vh;
	min-width: 50vw;
	@media screen and (max-width: $break-sm) {
		padding: 10px;
	}
	@media screen and (min-height: 600px) and (max-height: 700px) and (max-width: 480px) {
		max-height: 40vh;
	}
	@media screen and (min-height: 600px) and (max-height: 700px) and (min-width: 480px) {
		max-height: 65vh;
	}
	@media screen and (min-height: 700px) and (max-height: 800px) {
		max-height: 55vh;
	}
	@media screen and (min-height: 800px) and (max-height: 900px) {
		max-height: 60vh;
	}
	@media screen and (min-height: 900px) {
		max-height: 70vh;
	}
}

.single-step {
	margin-top: 16px;
	min-height: 200px;
	padding-top: 12px;
	overflow-y: auto;
	max-height: 70vh;
	border: 1px dashed #45c5d5;
	border-radius: 6px;
	@media screen and (max-width: $break-sm) {
		padding: 10px;
	}
	@media screen and (min-height: 600px) and (max-height: 800px) {
		max-height: 70vh;
	}
	@media screen and (min-height: 800px) {
		max-height: 75vh;
	}
}

.single-step-no-footer {
	margin-top: 16px;
	min-height: 200px;
	padding-top: 12px;
	overflow-y: auto;
	max-height: 90vh;
	@media screen and (max-width: $break-sm) {
		padding: 10px;
	}
	@media screen and (max-height: 600px) {
		max-height: 60vh !important;
	}
	@media screen and (max-height: 800px) {
		max-height: 75vh;
	}
}

.steps-disappear {
	@media screen and (max-width: 480px) {
		display: none !important;
	}
}
